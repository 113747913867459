<template>
  <div
    class="clinician-layout"
    @click="SET_SIDEBAR_ADD_STATUS"
  >
    <MyAccountClinician />
    <ModalWrapper />
    <ClinicianNavbar :user="user" />
    <ClinicianSidebar />
    <div class="clinician-content content">
      <div class="main-view">
        <router-view />
      </div>
      <div class="detail-view" />
    </div>
  </div>
</template>

<style scoped>
  .body-clinician-layout .clinician-layout {
    display: -ms-grid;
    display: grid;
    height: 100%;
  }
  .body-clinician-layout .clinician-layout .clinician-content {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100% auto;
    grid-template-columns: 100% auto;
  }
  .body-clinician-layout .clinician-layout .clinician-content .main-view {
    padding-top: 0;
    background: #dee5ed;
    position: relative;
  }
</style>

<script>
import ClinicianNavbar from './ClinicianNavbar'
import ClinicianSidebar from './ClinicianSidebar'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MyAccountClinician from '@/components/clinician/MyAccountClinician'
import ModalWrapper from '../modal/modalWrapper'

export default {
  name: 'PatientLayout',
  components: {
    ModalWrapper,
    ClinicianNavbar,
    ClinicianSidebar,
    MyAccountClinician
  },
  // Todo - https://owloutcomes.atlassian.net/browse/OWL-8407
  // beforeDestroy () {
  //   document.body.classList.remove('body-clinician-layout')
  // },
  computed: {
    ...mapGetters({
      user: 'loggedInUser'
    })
  },
  beforeCreate () {
    document.body.className = 'body-clinician-layout'
  },
  destroyed () {
    this.$store.dispatch('RESET_SCHEDULING_OPTIONS')
  },
  methods: {
    ...mapActions([
      'SET_SIDEBAR_ADD_STATUS'
    ]),
    ...mapMutations({
      resetMeasuresAssigned: 'measures/RESET_MEASURES_ASSIGNED_DURING_SESSION'
    })
  }
}
</script>
